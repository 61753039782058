const Activities = () => {
  return (
    <>
      <h1>Activities</h1>
      <h3>Feature coming soon!</h3>
    </>
  );
};

export default Activities;
