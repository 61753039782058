import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./db3.css";
import { ReactComponent as SearchIcon } from "./buttons/search-outline.svg";
import { useHistory } from "react-router-dom";

const CreationNotes = () => {
  return (
    <>
      <h1>Notes of Nature</h1>
    </>
  );
};

export default CreationNotes;
